import { createSlice } from '@reduxjs/toolkit'
import { IsFlagBulkOnboardingStateType } from 'redux/type'

export const isFlagBulkOnboardingSlice: any = createSlice({
  name: 'isFlagBulkOnboarding',
  initialState: {
    isFlagBulkOnboarding: false
  },
  reducers: {
    setIsFlagBulkOnboarding: (
      state: IsFlagBulkOnboardingStateType,
      action: { payload: boolean }
    ): void => {
      state.isFlagBulkOnboarding = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIsFlagBulkOnboarding } = isFlagBulkOnboardingSlice.actions

export default isFlagBulkOnboardingSlice.reducer
