import { createSlice } from '@reduxjs/toolkit'
import { IsFlagMailingAddressStateType } from 'redux/type'

export const isFlagMailingAddressSlice: any = createSlice({
  name: 'isFlagMailingAddress',
  initialState: {
    isFlagMailingAddress: false
  },
  reducers: {
    setIsFlagMailingAddress: (
      state: IsFlagMailingAddressStateType,
      action: { payload: boolean }
    ): void => {
      state.isFlagMailingAddress = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIsFlagMailingAddress } = isFlagMailingAddressSlice.actions

export default isFlagMailingAddressSlice.reducer
