import { createSlice } from '@reduxjs/toolkit'
import { IsFlagDisableEmployeeMovementStateType } from 'redux/type'

export const isFlagDisableEmployeeMovementSlice: any = createSlice({
  name: 'isFlagDisableEmployeeMovement',
  initialState: {
    isFlagDisableEmployeeMovement: false
  },
  reducers: {
    setIsFlagDisableEmployeeMovement: (
      state: IsFlagDisableEmployeeMovementStateType,
      action: { payload: boolean }
    ): void => {
      state.isFlagDisableEmployeeMovement = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIsFlagDisableEmployeeMovement } =
  isFlagDisableEmployeeMovementSlice.actions

export default isFlagDisableEmployeeMovementSlice.reducer
