import { createSlice } from '@reduxjs/toolkit'
import { IsFlagDashboardStateType } from 'redux/type'

export const isFlagDashboardSlice: any = createSlice({
  name: 'isFlagDashboard',
  initialState: {
    isFlagDashboard: false
  },
  reducers: {
    setIsFlagDashboard: (
      state: IsFlagDashboardStateType,
      action: { payload: boolean }
    ): void => {
      state.isFlagDashboard = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIsFlagDashboard } = isFlagDashboardSlice.actions

export default isFlagDashboardSlice.reducer
