import { FC } from 'react'
import 'aws'

import { BrowserRouter as Router } from 'react-router-dom'
import { Layout } from 'layouts/index'
import { ErrorBoundary } from 'HOC/ErrorBoundary'

import { BackgroundTasks } from 'components/Backgrounds'

import 'scss/index.scss'
// import unleashClient from 'unleash/unleashClient'
// import UnleashProvider from '@unleash/proxy-client-react'

interface IProps {}

const App: FC<IProps> = (): JSX.Element => {
  return (
    // <UnleashProvider unleashClient={unleashClient}>
    <ErrorBoundary>
      <Router>
        <BackgroundTasks />
        <Layout />
      </Router>
    </ErrorBoundary>
    // </UnleashProvider>
  )
}

export default App
