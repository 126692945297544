import { createSlice } from '@reduxjs/toolkit'
import { IsFlagInvoiceStateType } from 'redux/type'

export const isFlagInvoiceSlice: any = createSlice({
  name: 'isFlagInvoice',
  initialState: {
    isFlagInvoice: false
  },
  reducers: {
    setIsFlagInvoice: (
      state: IsFlagInvoiceStateType,
      action: { payload: boolean }
    ): void => {
      state.isFlagInvoice = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIsFlagInvoice } = isFlagInvoiceSlice.actions

export default isFlagInvoiceSlice.reducer
